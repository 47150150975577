.articles-list {
  margin-bottom: 4rem;

  &.swiper {
    overflow: visible;
    margin: 4rem auto;
    .articles-list {
      &__slider {
        width: 80%;
        @media (min-width: $sm-width) {
          width: 100%;
        }
      }
    }
    .swiper {
      overflow: visible;
      padding-bottom: 1rem;
      @media (min-width: $sm-width) {
        overflow: hidden;
      }
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 2rem;
  }
  &__articles {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;

    @media (min-width: $sm-width) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
    }

    @media (min-width: $md-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__no-articles {
    width: 100%;
    margin-top: 8rem;
    &__inner {
      @extend .h4;
      text-align: center;
    }
  }
}
