input,
textarea {
  border: 1px solid $secondary-light;
  border-radius: $radius;
  font-size: 1.4rem;
  display: block;
  width: 100%;
  padding: 1.5rem 2rem;
  font-family: $font-primary;
  outline: none;

  &:focus {
    outline: px solid $secondary;
  }
}

textarea {
  height: 14rem;
  margin-bottom: 1rem;
}

.input-wrapper {
  margin-bottom: 1rem;
}
