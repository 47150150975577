.category-card {
  width: 100%;

  &__inner {
  }

  &__image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__content {
    background-color: $light;
    text-align: center;
    height: 6rem;
    width: 80%;
    margin: -2rem auto auto;
    z-index: 10;
    position: relative;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .h3 {
      margin: auto;
    }
  }
}
