.login-form {
  .input-wrapper {
    margin-bottom: 2rem;
  }

  &__fields {
    width: 80%;
    margin: auto;
  }

  &__inner {
    h1 {
      margin-bottom: 2rem;
      font-size: 3.4rem;
    }

    .btn {
      margin-top: 2rem;
    }
  }
}
