.style-guide {
  border: 1px solid red;

  &__title,
  &__item__title {
    font-family: $font-primary;
    text-align: left;
    color: $dark !important;

    padding-left: 0;
    padding-bottom: 0;
    &:after,
    &:before {
      content: none;
    }
  }

  &__item__content {
    border-bottom: 1px solid $dark;

    .btn {
      margin-bottom: 1rem;
    }
  }
}
