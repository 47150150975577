@import "swiper/css";
@import "swiper/css/grid";
@import "swiper/css/effect-fade";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

.slider {
  position: relative;
  .swiper {
    height: 150vw;

    @media (min-width: $sm-width) {
      height: 30vw;
    }
    &-slide {
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-button-prev,
    &-button-next {
      color: $secondary;

      &::after {
        font-size: 3rem;
      }
    }
  }

  .swiper-pagination-bullet {
    background-color: $secondary;
    opacity: 0.5;

    &-active {
      opacity: 1;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: calc(50% - 1.5rem);
    z-index: 10000;
    padding: 0 2rem;
  }
}
