.icons-nav {
  display: inline-block;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    margin: 0 0.5rem;
    cursor: pointer;

    .icon {
      fill: $transLight;
      transition: fill 0.3s ease;
    }

    &:hover {
      .icon {
        fill: $secondary-light;
      }
    }
  }
}
