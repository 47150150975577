.faqs {
  margin-top: 6rem;
  &__items {
    margin-top: 2rem;
  }
  &__item {
    margin-bottom: 1rem;
    background-color: $bg-grey;
    border-radius: 2rem;
    padding: 1rem 2rem;
    transition: all 0.3s ease;
    &.active {
      background-color: $secondary;
      .faqs {
        &__item {
          &__label {
            color: $light;
          }

          &__icon {
            background-color: $primary;
            .icon {
              fill: $light;
            }
          }

          &__content {
            * {
              color: $light;
            }
          }
        }
      }
    }
    &__label {
      cursor: pointer;
      user-select: none;
      padding: 1rem;
      border-radius: default(radius);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      color: $primary;
      transition: all 0.3s ease;
      gap: 2rem;

      &:hover {
        background-color: color(tertiary);
        opacity: 0.8;
        color: color(mono, light);

        &::after {
          color: color(mono, light);
        }
      }
    }

    &__icon {
      background-color: $light;
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      border-radius: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      @media (min-width: $sm-width) {
        width: 4rem;
        height: 4rem;
        min-width: 4rem;
        min-height: 4rem;
      }

      .icon {
        fill: $primary;
        transition: all 0.3s ease;
      }
    }

    &__content {
      overflow: hidden;
      transition: max-height 0.3s ease;
      color: $primary;

      &__inner {
        padding: 0 4rem 2rem 2rem;
        margin: 1rem 0 2rem 0;

        * {
          color: $primary;
        }
      }
    }
  }
}
