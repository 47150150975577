.main-header {
  position: fixed;
  top: 1rem;
  width: 100%;
  margin: auto;
  padding: 0 1rem;
  text-align: center;
  height: 5.5rem;
  display: flex;
  align-items: center;
  z-index: 999999;

  @media (min-width: $md-width) {
    height: 5.5rem;
    top: 2rem;
    padding: 0 2rem;
  }

  &__inner {
    max-width: 1200px;
    margin: auto;
    width: 100%;
    z-index: 1001;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $primary;
    border-radius: 10rem;
    padding: 1rem 1rem;

    @media (min-width: $sm-width) {
      padding: 1rem 2rem;
    }

    &__logo {
      text-decoration: none;
      z-index: 1001;
      margin-left: 1rem;

      .logo {
        &-icon {
          width: 2rem;
          margin-top: -2rem;
          img {
            width: 100%;
            height: auto;
          }
        }

        &-title {
        }
      }
    }

    &__mobile-nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (min-width: $md-width) {
        display: none;
      }

      .mobile-nav {
        // background-color: $secondary;
        // border-radius: 10rem;
        // padding: 0.5rem;
        &__close {
          display: none;
        }

        &__close,
        &__open {
          background-color: $secondary;
          border-radius: 10rem;
          padding: 0.5rem;
          z-index: 1002222;
          position: relative;
        }
        &.open {
          .mobile-nav {
            padding: 0rem !important;
            &__close {
              display: block;
            }

            &__open {
              display: none;
            }
            &__content {
              left: 0;
            }
          }
        }
        .navigator {
          margin-top: 10rem;

          &__inner {
            text-align: left;
            padding: 0 4rem;

            .nav-item {
              position: relative;
              &__link {
                padding: 1.5rem 0;
                display: block;
                text-decoration: none;
                border-bottom: 1px solid $light;

                &__label {
                  color: $light;
                }
              }

              &.active {
                &::after {
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 1rem;
                  background-color: $secondary;
                  position: absolute;
                  display: block;
                  top: calc(50% - 3px);
                  left: -1.5rem;
                }
                .nav-item {
                  &__link {
                    pointer-events: none;
                    &__label {
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
        }

        .icon {
          fill: $light;
          width: 3rem;
          cursor: pointer;
        }

        .modal {
          margin-top: -2rem;
          &__inner {
            background-color: $primary;
            padding: 0;
            max-width: none !important;
          }

          &__close {
            display: none;
          }
        }
      }
    }

    &__desktop-nav {
      display: none;

      @media (min-width: $md-width) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .navigator {
        display: none;

        @media (min-width: $sm-width) {
          display: inline-block;
        }

        &__inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .nav-item {
          position: relative;

          &:after {
            content: "";
            width: 0;
            margin: auto;
            height: 0.2rem;
            background-color: $light;
            position: absolute;
            bottom: -5px;
            left: calc(50%);
            padding: inherit;
            transition: all 0.3s ease-in-out;
          }

          &.active {
            &:after {
              width: calc(100% - 2rem);
              left: 1rem;
              background-color: $secondary;
            }
            .main-nav__item__link__label {
              color: $secondary;
            }
          }
          &__link {
            padding: 1rem;
            text-decoration: none;

            &__label {
              color: $light;
              font-family: $font-primary;
              transition: all 0.3s ease;
            }
          }

          &:hover {
            &:after {
              width: calc(100% - 2rem);
              left: 1rem;
            }
            .nav-item {
              &__link {
                &__label {
                  // color: $secondary;
                }
              }
            }
          }
        }
      }
    }

    .right-nav {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 1000;

      @media (min-width: $md-width) {
        margin-top: 0.2rem;
        align-items: flex-start;
      }

      &__inner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &__item {
        margin: 0 0.5rem;
        cursor: pointer;

        .search {
          &.disabled {
            pointer-events: none;
          }
        }

        .icon {
          fill: $transLight;
          transition: fill 0.3s ease;

          &.logged {
            fill: $secondary;
          }
        }

        &:hover {
          .icon {
            fill: $secondary-light;
          }
        }
      }
    }
  }

  &__bottom {
    display: none;

    @media (min-width: $md-width) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__mobile-separator {
    height: 5.5rem;
    display: none;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  &__desktop-separator {
    height: 5.5rem;
    display: none;

    @media (min-width: $md-width) {
      display: block;
      display: none;
    }
  }

  .search-bar {
    position: absolute;
    top: 5.5rem;
    left: 0;
    overflow: hidden;
    padding: 0 2rem;
    transition: all 0.3s ease;

    &__inner {
      padding: 2rem 0;
      opacity: 0;
      max-width: 1200px;
      position: relative;
      margin: auto;
    }

    &__form {
      opacity: 0;
      transition: all 0.2s ease;

      &.in {
        opacity: 1;
      }
      &.out {
        opacity: 0;
      }
    }

    &.open {
      .search-bar__inner {
        opacity: 1;
      }
    }
  }
}
