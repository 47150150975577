.block-presentation {
  position: relative;
  padding: 4rem 0;
  background-color: $bg;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: inherit;

    @media (min-width: $sm-width) {
      flex-direction: row-reverse;
    }
  }

  &__content {
    color: inherit;
    margin-top: 2rem;
    @media (min-width: $sm-width) {
      margin-right: 4rem;
      margin-top: 0;
    }
    &__title {
      color: inherit;
      font-size: 4rem;
      text-align: center;
      @media (min-width: $sm-width) {
        text-align: left;
      }
    }

    &__description {
      color: inherit;
      margin-top: 1rem;
      text-align: center;
      @media (min-width: $sm-width) {
        text-align: left;
      }
    }
    &__cards {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: $bg-white;
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 3px 3px 10px rgba($dark, 0.2);
      margin-top: 4rem;

      @media (min-width: $sm-width) {
        margin-top: 2rem;
      }
    }

    .btn {
      margin-top: 4rem;
      display: flex;

      @media (min-width: $sm-width) {
        margin-top: 2rem;
        display: inline-block;
      }
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    &:first-child {
      border-right: 2px solid $primary;
    }
    &__size {
      font-size: 3rem;
      font-weight: 700;
      display: block;
    }
    &__label {
      display: block;
      margin-top: 1rem;
    }
  }

  &__image {
    border-radius: 1rem;
    overflow: hidden;
  }
}
