.block-cards {
  position: relative;
  &__inner {
    color: inherit;
    h2 {
      color: inherit;
      margin-bottom: 2rem;
    }
  }

  &__items {
    .slider {
      .swiper {
        overflow: visible;
        height: fit-content;
        &-slide {
          height: auto !important;
        }
        &-pagination {
          bottom: -3rem;
        }
      }
    }
  }
  &__card {
    overflow: hidden;
    height: 100%;

    &__inner {
      color: inherit;
      display: flex;
      height: 100%;
      flex-direction: column;
    }

    a {
      text-decoration: none;
    }

    &__image {
      width: 100%;
      height: 0;
      padding-top: 60%;
      display: block;
      overflow: hidden;
      position: relative;
      &__ctn {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__content {
      color: inherit;
      padding: 2rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 24rem;

      * {
        color: inherit;
      }
    }

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 2rem;
    }
    &__link {
      display: inline-block;
    }
  }
}

.slice-card {
}
