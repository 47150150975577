.h1,
h1 {
  font-family: $font-tertiary;
  font-size: 3.2rem;
  position: relative;
  font-weight: 300;
  line-height: 3.2rem;

  @media (min-width: $sm-width) {
    font-size: 3.6rem;
    line-height: 3.6rem;
  }

  @media (min-width: $md-width) {
    font-size: 4.4rem;
    line-height: 4.4rem;
  }

  &.underlined {
    padding-left: 3.2rem;
    padding-bottom: 2rem;
    &:after {
      content: "";
      background-color: $primary;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      position: absolute;
      top: 1.4rem;
      left: 0rem;
      display: block;
    }

    &:before {
      content: "";
      background-color: $secondary;
      width: 36rem;
      height: 0.5rem;
      border-radius: 1rem;
      position: absolute;
      bottom: 1.4rem;
      left: 0rem;
      display: block;
    }
  }
}

.h2,
h2 {
  font-family: $font-tertiary;
  font-size: 2.8rem;
  font-weight: 700;

  @media (min-width: $sm-width) {
    font-size: 3rem;
  }

  @media (min-width: $md-width) {
    font-size: 3.4rem;
  }
}

.h3,
h3 {
  font-size: 2rem;
  font-family: $font-tertiary;
  @media (min-width: $md-width) {
    font-size: 2rem;
  }
}

.h4,
h4 {
  font-family: $font-secondary;
  font-size: 2rem;
}

.h5,
h5 {
  font-family: $font-primary;
  font-size: 1.2rem;
}

.h6,
h6 {
  font-family: $font-primary;
  font-size: 1.2rem;
}
