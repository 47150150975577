.btn {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &-primary {
    font-family: $font-secondary;
    background-color: $primary;
    border: 2px solid $primary;
    border-radius: 3rem;
    color: $light;
    font-size: 1.4rem;
    padding: 1rem 6rem;
    transition: color 0.3s ease, background-color 0.3s ease;
    align-items: center;
    justify-content: center;
    text-align: center;

    .icon {
      fill: $light;
      transition: fill 0.3s ease;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $light;
      color: $primary;

      .icon {
        fill: $primary;
      }

      a {
        color: $secondary;
      }
    }
  }

  &-secondary {
    border: 1px solid $primary;
    color: $primary;
    font-family: $font-primary;
    border-radius: 3rem;
    font-size: 2rem;
    outline: none;
    padding: 1rem 6rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.5s ease, color 0.5s ease;

    &:hover {
      background-color: $primary;
      color: $light;
    }

    &-light {
      border: 1px solid $light !important;
      color: $light;
      text-transform: none;
      font-family: $font-secondary;
      font-weight: 700;

      &:hover,
      &:focus,
      &:active {
        background-color: $light;
        color: $tertiary;
      }
    }
  }

  &-white {
    border: 1px solid $light;
    color: $light;
    font-family: $font-primary;
    border-radius: 3rem;
    font-size: 1.4rem;
    outline: none;
    padding: 1rem 6rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.5s ease, color 0.5s ease;

    .icon {
      fill: $light;
      transition: fill 0.3s ease;
    }

    &:hover {
      background-color: $light;
      color: $primary;

      .icon {
        fill: $primary;
      }
    }

    &-light {
      border: 1px solid $light !important;
      color: $light;
      text-transform: none;
      font-family: $font-secondary;
      font-weight: 700;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        background-color: $light;
        color: $tertiary;
      }
    }

    &-full {
      background-color: $light;
      color: $primary;

      .icon {
        fill: $primary;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: $light;

        .icon {
          fill: $light;
        }
      }
    }
  }

  &-link {
    color: $primary;
    position: relative;

    &::before {
      content: " ";
      width: 0rem;
      height: 0.3rem;
      background-color: $secondary;
      display: block;
      position: absolute;
      left: 0;
      bottom: -0.5rem;
      transition: all 0.3s ease;
    }
    &:hover,
    &:focus,
    &:active {
      color: $secondary;

      &::before {
        width: 100%;
      }
    }
  }

  &-arrow {
    background-color: $secondary;
    width: 3rem;
    height: 3rem;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
    }

    .icon {
      fill: $light;
    }
  }
}
