.block-images-slider {
  position: relative;

  &__inner {
    position: relative;
    color: inherit;
  }
  &__text {
    pointer-events: none;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;

    color: inherit;
    .richtext {
      color: inherit;
      * {
        text-align: center;
        color: inherit;
      }
    }
  }
}
