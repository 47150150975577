.article-card {
  border-radius: 4rem 0;
  overflow: hidden;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 3px 3px 10px 0px rgba($primary, 0.5);
  }

  &__image {
    width: 100%;
    height: 0;
    min-height: 0;
    padding-top: 80%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1s ease, filter 0.5s ease;
    }
  }

  &__content {
    padding: 2rem;
  }

  &__title {
    height: 5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    .h4 {
      transition: all 0.3s ease;
    }

    &:hover {
      .h4 {
        color: $primary;
      }
    }

    a {
      text-decoration: none;
    }
  }
  &__reading-time,
  &__reading-time span {
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 1rem;

    span {
      color: $secondary;
      font-weight: 400;
    }
  }

  &__description {
    margin-top: 1rem;
    height: 8rem;

    .rich-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
