@import "~web/assets/scss/commons/variables";
@import "~web/assets/scss/commons/reset";
@import "~web/assets/scss/commons/helpers";
@import "~web/assets/scss/commons/richtext";
@import "~web/components/components";
@import "~web/modules/modules";
@import "~web/layout/layout";
@import "~web/pages/pages";

html {
  font-family: $font-primary;
  font-size: 62.5%;
  overflow-x: hidden;
  text-size-adjust: 100%;
  color: $text-md;
  position: relative;
  overflow: hidden !important;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  background-color: #f1f1f1;
}

* {
  -webkit-tap-highlight-color: transparent;
  color: $primary;
}

.page-content {
  margin: auto;
  max-width: 1200px;
  padding-bottom: 5rem;
  width: 100%;
}

.lg-thumb-item {
  border: none !important;
}
