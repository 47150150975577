.modal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transition: all 0.3s ease;

  &.float,
  &.float-top,
  &.float-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    &.modal-open {
      opacity: 1;
      visibility: visible;

      .modal__inner {
        transform: translateY(0) !important;
      }
    }

    .modal__inner {
      position: relative;
      background-color: white;
      width: calc(100% - 4rem);
      // height: 90%;
      border-radius: 0.5rem;
      z-index: 1000;
      padding: 3rem 1rem;
      transition: all 0.3s ease;
      transform: translateY(-20rem);
    }

    &.small {
      .modal__inner {
        max-width: 50rem;
      }
    }

    &.medium {
      .modal__inner {
        max-width: 70%;
      }
    }

    &.large {
      .modal__inner {
        max-width: 100%;
      }
    }
  }

  &.float {
    align-items: center;

    &-top {
      align-items: flex-start;

      .modal__inner {
        top: 8rem;
      }
    }
    &-bottom {
      align-items: flex-end;

      .modal__inner {
        bottom: 8rem;
        top: auto;
        transform: translateY(20rem);
      }
    }
  }

  &.left,
  &.right {
    opacity: 0;
    visibility: hidden;

    &.small {
      .modal__inner {
        max-width: 50rem;
      }
    }

    &.medium {
      .modal__inner {
        max-width: 70%;
      }
    }

    &.large {
      .modal__inner {
        max-width: 100%;
      }
    }
    .modal__inner {
      position: absolute;
      background-color: white;
      width: 100%;
      height: 100%;
      z-index: 1000;
      padding: 3rem 1rem;
      transition: all 0.3s ease;
      top: 0;
    }

    &.modal-open {
      opacity: 1;
      visibility: visible;
    }
  }

  &.left {
    .modal__inner {
      left: -100%;
    }
    &.modal-open {
      .modal__inner {
        left: 0;
      }
    }
  }

  &.right {
    .modal__inner {
      right: -100%;
    }
    &.modal-open {
      .modal__inner {
        right: 0;
      }
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    .icon {
      fill: $primary;
      width: 3rem;
    }
  }

  &__backdrop {
    background-color: rgba(#000, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
  }
}
