.back-to-top {
  position: fixed;
  bottom: 5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  margin: auto;
  left: 0;
  pointer-events: none;
  z-index: 999;

  @media (min-width: $md-width) {
    bottom: 15rem;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    max-width: 1200px;
    margin: auto;
    text-align: right;
    &__button {
      margin-left: auto;
      margin-right: 2rem;
      pointer-events: all;
      transition: all 0.3s ease;
      background-color: $secondary;
      width: 3.5rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10rem;
      cursor: pointer;

      @media (min-width: $md-width) {
        width: 5rem;
        height: 5rem;
        margin-right: 5rem;
      }
      &:hover {
        background-color: $primary;
      }
      .icon {
        fill: $light;
        transform: rotate(-90deg);
        margin-top: -0.2rem;
      }
    }
  }
}
