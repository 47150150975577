.header-separator {
  height: 6rem;
}

/* grid & space */

hr {
  border-top: 1px solid $primary;
}

.no-underline {
  text-decoration: none !important;
}

.cursor {
  cursor: pointer;
}

.border {
  border: 1px solid $border;
}

.valign-middle {
  > * {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}

.md-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 107rem;
}

.sm-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 65rem;
}

.xs-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 45rem;
}

.unvisible {
  display: none;
}

/* bg color */
.bg-white {
  background-color: $light;
}

.bg-black {
  background-color: $dark;
  color: $light;
}

.bg-grey {
  background-color: $bg;
}

.bg-tertiary {
  background-color: $tertiary;
}

.bg-tertiary-light {
  background-color: rgba($tertiary, 0.1);
}

.color-secondary {
  color: $secondary;
}

.color-tertiary {
  color: $tertiary;
}

/* links */
.link {
  &:hover {
    text-decoration: underline;
  }
}

.link2 {
  position: relative;

  &::after {
    background-color: $text;
    bottom: -0.5rem;
    content: "";
    height: 0.05rem;
    left: 0;
    position: absolute;
    transition: all 0.3s linear;
    width: 100%;
  }

  &:hover {
    &::after {
      background-color: $primary;
    }
  }
}

// autres helper
.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.position-relative {
  position: relative;
}

.position-abolute {
  position: absolute;
}

.underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.barre {
  text-decoration: line-through;
}

.upper {
  text-transform: uppercase;
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.nw {
  white-space: nowrap;
}

.cover {
  background-size: cover;
}

.no-repeat {
  background-repeat: no-repeat;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-responsive {
  max-width: 100%;
}

.img-responsive-ib {
  max-width: 100%;
}

.fix-ht-img {
  font-size: 0;
  line-height: 0;
}

.strong {
  font-weight: $weight-bold;
}

.strong-medium {
  font-weight: $weight-md;
}

.no-margin {
  margin: 0 !important;
}

.help-block {
  color: $text;
  font-size: 1.4rem;
  line-height: 1.6;
}

.break {
  word-break: break-all;
}

.mobile {
  @media (min-width: $sm-width) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (min-width: $sm-width) {
    display: block;
  }
}
