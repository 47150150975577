.block-separator2 {
  position: relative;
  height: 8rem;
  &__inner {
    height: 100%;
    display: flex;
    justify-content: center;

    &.top {
      align-items: flex-start;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.middle {
      align-items: center;
    }
  }

  &__line {
    background-color: $secondary;
    height: 0.2rem;
    width: 100%;
  }
}
