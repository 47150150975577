.alert-message {
  margin-bottom: 2rem;

  &__content {
    padding: 1rem;
    text-align: center;
    border-radius: $radius;
    &.success {
      color: $alert-success;
      border: 2px solid $alert-success;
    }

    &.danger {
      color: $alert-danger;
      border: 2px solid $alert-danger;
    }
  }
}
