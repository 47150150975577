.logo {
  &-title {
    display: inline;
    text-align: center;
    font-family: $font-secondary;
    font-size: 8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $light;
  }

  &-icon {
    img {
      height: 100%;
    }
  }
}
