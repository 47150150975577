@import "~web/layout/Header/Header";
@import "~web/layout/Footer/Footer";

.generic-layout {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  &__content {
    position: relative;
    margin: auto;
    min-height: 100vh;
  }
}

.page {
}

.ctn {
  width: 100%;
  margin: auto;
  padding: 0 2rem;
  &-full {
    max-width: 100%;
    padding: 0;
  }

  &-large {
    max-width: $lg-width;
  }

  &-medium {
    max-width: $md-width;
  }
  &-small {
    max-width: $sm-width;
  }
}
