.progress-reading-bar {
  background-color: $light;
  box-shadow: 3px 0 10px $bg-grey;
  width: 100%;
  padding: 0.5rem 1rem 1rem;

  @media (min-width: $sm-width) {
    padding: 1rem;
  }
  &__info {
    white-space: nowrap;
    margin-right: 2rem;
    font-size: 1.2rem;

    span {
      font-size: 1.2rem;
      font-weight: 900;
      color: $secondary;
    }
  }
  &__inner {
    @media (min-width: $sm-width) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .progress-bar {
      width: 100%;
      height: 0.5rem;
      background-color: $bg-grey;
      border-radius: 1rem;
      overflow: hidden;
      &__inner {
        height: 100%;
        background-color: $primary;
      }
    }
  }
}
