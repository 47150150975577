.richtext {
  font-size: 1.4rem;
  min-height: 2rem;
  font-weight: normal;

  strong {
    font-weight: 700;
  }

  p,
  span {
    min-height: 2rem;
    font-weight: normal;
  }

  i,
  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    list-style-position: inside;
    padding-left: 1rem;
  }
  ol {
    list-style: decimal;
    list-style-position: inside;
    padding-left: 1rem;
  }

  h1 {
    @extend .h1;
  }

  h2 {
    @extend .h2;
  }

  h3 {
    @extend .h3;
  }

  h4 {
    @extend .h4;
  }

  h5 {
    @extend .h5;
  }

  h6 {
    @extend .h6;
  }

  ul {
    list-style-position: outside;
    padding-left: 2rem;
    li {
    }
  }
}
