.text-image {
  padding: 0;
  margin-top: 6rem;

  &.with-bg {
    background-color: $bg-grey;
    padding: 4rem 0;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
    max-width: 540px !important;

    @media (min-width: $sm-width) {
      max-width: 700px !important;
    }
    @media (min-width: $md-width) {
      max-width: $lg-width !important;
    }
  }

  &.left {
    .text-image {
      &__inner {
        flex-direction: column-reverse;
        @media (min-width: $md-width) {
          flex-direction: row;
        }
      }

      &__image {
        &__inner {
          clip-path: polygon(
            95% 0,
            100% 5%,
            100% 40%,
            80% 70%,
            80% 95%,
            75% 100%,
            0 100%,
            0 0
          );
        }
      }
    }
  }

  &.right {
    .text-image {
      &__inner {
        flex-direction: column-reverse;
        @media (min-width: $md-width) {
          flex-direction: row-reverse;
        }
      }

      &__image {
        &__inner {
          clip-path: polygon(
            5% 0,
            100% 0,
            100% 100%,
            25% 100%,
            20% 95%,
            20% 70%,
            0 40%,
            0 5%
          );
        }
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    filter: url("#filter");

    @media (min-width: $md-width) {
      width: 45rem;
      height: 45rem;
      padding: 0;
    }

    @media (min-width: $lg-width) {
      width: 55rem;
      height: 55rem;
    }

    &__inner {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;

    @media (min-width: $md-width) {
      width: 65rem;
      min-height: 45rem;
    }

    @media (min-width: $lg-width) {
      width: 55rem;
      min-height: 55rem;
    }

    ul {
      margin-top: 2rem;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__title {
    font-weight: 700;
    margin: 0 !important;
  }

  &__text {
  }

  .filter {
    visibility: hidden;
    position: absolute;
    width: 0px;
    height: 0px;
  }
}
