.error-204 {
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //   font-size: 5rem;

  &__content {
    text-align: center;
  }
}
