.block-reviews {
  padding: 0;
  &__inner {
    position: relative;
  }
  &__title {
    text-align: center;
    color: $primary;
    font-weight: 700;
  }
  &__cards {
    margin: 4rem auto 0;
    @media (min-width: $sm-width) {
      width: 90%;
    }

    @media (min-width: $xl-width) {
      width: 100%;
    }

    .slider {
      .swiper {
        height: auto;

        &-pagination {
          position: relative;
          margin-top: 2rem;
        }
      }
    }
  }

  &__slider {
    &__actions {
      display: none;
      padding: 0;
      pointer-events: none;
      align-items: center;
      justify-content: space-between;
      top: calc(50%);
      left: 0;
      position: absolute;
      width: 100%;

      @media (min-width: $sm-width) {
        display: flex;
      }

      .btn {
        pointer-events: all;
        position: relative;
      }

      .reviews-prev {
        left: 2rem;
        transform: rotate(180deg);

        @media (min-width: $xl-width) {
          left: -2rem;
        }
      }

      .reviews-next {
        right: 2rem;

        @media (min-width: $xl-width) {
          right: -2rem;
        }
      }
    }
  }
  &__card {
    text-align: center;
    div {
      display: block;
    }
    &__name {
      font-weight: bold;
      color: $primary;
      font-size: 2rem;
    }
    &__category {
      font-style: italic;
      font-size: 1.2rem;
    }
    &__comment {
      font-style: italic;
      margin-top: 1rem;
    }
  }

  &__actions {
    text-align: center;
    margin-top: 5rem;
  }
  .btn {
    text-transform: none;
  }
}
