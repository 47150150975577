.block-richtext {
  &__inner {
    color: inherit;
    .richtext {
      color: inherit;
      * {
        color: inherit;
      }
    }
  }
}
