.block-hero {
  max-width: $xl-width;
  margin: auto;
  @media (min-width: $sm-width) {
    height: 600px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .filter {
    visibility: hidden;
    position: absolute;
    width: 0px;
    height: 0px;
  }

  &__left {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    filter: url("#filter");
    height: 140vw;

    @media (min-width: $xs-width) {
      height: 100vw;
    }

    @media (min-width: $sm-width) {
      height: 100%;
      margin-left: -2rem;
      width: 75%;
    }

    @media (min-width: $xl-width) {
      margin-left: 0;
      width: 70%;
    }

    &__inner {
      width: 100%;
      background-color: $secondary;
      height: 100%;
      margin: 0;

      clip-path: polygon(0 0, 100% 0, 100% 80%, 63% 80%, 33% 100%, 0 100%);

      @media (min-width: $sm-width) {
        clip-path: polygon(
          0 0,
          90% 0%,
          90% 40%,
          100% 63%,
          100% 94%,
          96% 100%,
          0 100%
        );
      }
    }
    &__content {
      position: absolute;
      z-index: 999;
      width: 100%;
      height: 90%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 4rem;
      color: $light;
      text-align: center;

      @media (min-width: $xs-width) {
        height: 100%;
      }

      @media (min-width: $sm-width) {
        text-align: left;
        width: 90%;
      }

      @media (min-width: $md-width) {
        padding: 0 6rem;
        max-width: 90%;
      }

      &__inner {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 3rem;
      }

      * {
        color: $light;
      }

      &__title {
        color: $light;
        width: 100%;
      }

      &__text {
        color: $light;
      }

      &__actions {
        display: none;
        gap: 1rem;
        width: 100%;

        @media (min-width: $sm-width) {
          display: flex;
          flex-direction: column;
        }

        @media (min-width: 850px) {
          flex-direction: row;
        }
      }
      .richtext {
        color: inherit;

        @media (min-width: $sm-width) {
          // margin-left: 4rem;
          max-width: 80%;
        }
        * {
          text-align: left;
          color: inherit;
        }

        h1 {
          font-weight: 700;
          line-height: 4rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__right {
    filter: url("#filter");
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-top: -24vw;
    height: 80vw;

    @media (min-width: $xs-width) {
      margin-top: -16%;
    }

    @media (min-width: $sm-width) {
      margin-top: 0;
      height: 100%;
      margin-left: -5%;
      margin-right: -2rem;
      width: 45%;
    }

    @media (min-width: $xl-width) {
      margin-right: 0;
      width: 40%;
    }

    &__inner {
      width: 100%;
      height: 100%;
      background-color: $primary-light;
      height: 100%;
      margin: 0;
      clip-path: polygon(0 35%, 33% 35%, 63% 0, 100% 0, 100% 100%, 0 100%);

      @media (min-width: $xs-width) {
        clip-path: polygon(0 24%, 33% 24%, 63% 0, 100% 0, 100% 100%, 0 100%);
      }

      @media (min-width: $sm-width) {
        clip-path: polygon(
          0 0,
          100% 0,
          100% 100%,
          26% 100%,
          18% 94%,
          18% 63%,
          0 40%
        );
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 0;
      padding: 28vw 2rem 0;
      left: 2rem;
      height: 100%;
      width: calc(100% - 4rem);
      background-color: rgba(0, 0, 0, 0.5);

      @media (min-width: $xs-width) {
        padding: 18vw 2rem 0;
      }

      .btn {
        width: 100%;
      }

      @media (min-width: $sm-width) {
        display: none;
      }
    }
  }
}
