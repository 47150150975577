.block-reinsurance {
  position: relative;
  &__inner {
    position: relative;
  }
  &__title {
    text-align: center;
    font-size: 3rem;

    @media (min-width: $sm-width) {
      font-size: 4rem;
    }
  }

  &__items {
    margin-top: 3rem;
    text-align: center;

    @media (min-width: $sm-width) {
    }
  }

  &__item {
    display: inline-block;
    vertical-align: top;

    &__image {
      width: 70%;
      height: 0;
      padding-top: 70%;
      position: relative;
      margin: auto;
      &__inner {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__label {
      margin-top: 1rem;
      text-align: center;
      label {
        font-weight: 700 !important;
      }
    }
  }
}
