@import "~web/components/atoms/Typo/fonts/fonts";
@import "~web/components/atoms/Typo/Heading/Heading";

* {
  font-family: $font-primary;

  color: #000;
  font-style: normal;
  font-weight: 300;
  line-height: 2.2rem;
}

.cite {
  font-size: 3rem;
  font-style: italic;
  line-height: 3.4rem;
}

.text {
  font-family: $font-primary;
  font-size: 1.4rem;
  line-height: 2.4rem;

  &-big {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  &-small {
    font-size: 1rem;
  }

  &-center {
    text-align: center !important;
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }
}

.upc {
  text-transform: uppercase;
}

.lwc {
  text-transform: lowercase;
}

.cap {
  text-transform: capitalize;
}

strong {
  font-weight: 700;
}

.strong-medium {
  font-weight: 500;
}

a {
  color: inherit;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    color: $primary;
  }
}

.fancy-title {
  color: $primary;
  font-family: $font-primary;
  font-size: 5rem;
  line-height: normal;
  padding: 0;
  width: 100%;
  text-align: center;

  @media (min-width: $sm-width) {
    font-size: 6.5rem;
  }

  @media (min-width: $md-width) {
    font-size: 8rem;
  }
}

.font-primary {
  font-family: $font-primary;
}

.font-primary-medium {
  font-family: $font-primary;
}

.font-secondary {
  font-family: $font-secondary;
}

.font-secondary-light {
  font-family: $font-primary;
}

.style-title {
  font-family: inherit;
  font-weight: $weight-md;
}

strong,
b {
  font-weight: $weight-md;
}

.barre {
  text-decoration: line-through;
}

.body-regular {
  font-size: 1.6rem;
}

.body-large {
  font-size: 1.8rem;
}

.body-small {
  font-size: 1.4rem;
}

.green {
  color: $primary;
}
.orange {
  color: $secondary;
}
