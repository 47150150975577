.full-logo {
  height: 100%;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }
  .logo {
    &-icon {
      // width: 2rem;
      height: 3rem;
    }

    &-title {
      font-size: 2.4rem;
      margin-left: 1rem;
    }
  }
}
