.cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  background-color: $light;
  box-shadow: -0.3rem 0 1rem rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
  z-index: 100;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    max-width: 100rem;
    margin: auto;
    text-align: center;

    @media (min-width: $sm-width) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
  }

  &__content {
    margin-bottom: 2rem;
    @media (min-width: $sm-width) {
      margin-right: 10rem;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    color: $secondary;
    margin-bottom: 1rem;
    display: block;
  }
}
