.lang-selector {
  position: relative;
  &__current {
    position: relative;
    z-index: 999;
  }
  &__items {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 3rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    cursor: default;

    &.active {
      opacity: 1;
      visibility: visible;
    }
    &__inner {
      background-color: $light;
      padding: 2rem 0;
      border-radius: 0.5rem;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.5);

      @media (min-width: $md-width) {
        padding: 1rem 0;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 2rem;
    transition: all 0.3s ease;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    @media (min-width: $md-width) {
      padding: 0.5rem 1rem;
    }

    &:hover {
      background-color: $bg;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
}
