// Breakpoint
$xxxl-width: 1700px;
$xxl-width: 1500px;
$xl-width: 1320px;
$lg-width: 1200px;
$md-width: 992px;
$sm-width: 700px;
$xs-width: 480px;
$xxs-width: 360px;

// Colors

$primary: #0b7c80;
$primary-light: #195b5e;
$secondary: rgb(203, 113, 17);
$secondary-light: rgba($secondary, 0.5);
$tertiary: #ea9a28;
$light: #fff;
$transLight: rgba(#fff, 0.5);
$dark: #000;
$border: #cbc8bf;
$text: #000000;
$text-md: #2f2f2f;
$bg: #cdf6ff;
$bg-white: #fff;
$bg-grey: #d9d9d9;
$alert-success: #76d46c;
$alert-warning: orange;
$alert-danger: #e90a4e;

$font-primary: "Open Sans";
$font-secondary: "Open Sans";
$font-tertiary: "Open Sans";

$weight: 300;
$weight-md: 500;
$weight-bold: 700;

$radius: 1rem;
