.construction-page {
  background: #0b7c80;
  font-family: "Courier New", Courier, monospace;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    color: white;
  }

  p {
    margin: 0;
  }
}
