.main-footer {
  background: $primary;
  height: auto;
  padding: 3rem;
  margin-top: 6rem;

  &__inner {
    @media (max-width: $md-width) {
      max-width: 540px !important;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    @media (min-width: $md-width) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0;
    }
    &__nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .navigator {
        margin-top: 2rem;

        @media (min-width: $md-width) {
          padding-left: 3rem;
        }
        &__inner {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;

          @media (min-width: $md-width) {
            align-items: flex-start;
          }
          .nav-item {
            &__link {
              &:hover {
                .nav-item__link__label {
                  color: $secondary;
                }
              }
              &__label {
                color: $light;
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
    }

    &__map {
      position: relative;

      &__ctn {
        max-width: 30rem;
        margin: auto;
      }

      &__inner {
        width: 100%;
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        border-radius: 2rem;
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: $md-width) {
        align-items: flex-end;
      }
      gap: 2rem;
      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;

        @media (min-width: $md-width) {
          flex-direction: column;
        }

        .btn {
          padding: 1rem 2rem;
          width: 16rem;
        }
      }

      &__mail {
        color: $light;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;

        .btn {
          &:hover {
            color: $secondary;
            .icon {
              fill: $secondary;
            }
          }
        }

        .icon {
          margin-top: 4px;
          fill: $light;
          transition: all 0.3s ease;
        }
      }
    }
  }

  &__middle {
    margin-top: 3rem;
    padding: 3rem 0;
    border-top: 1px solid rgba($light, 0.2);
    &__social {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .btn {
        .icon {
          fill: $light;
          width: 4rem;
        }
      }
    }
  }

  &__bottom {
    padding: 3rem 0;
    border-top: 1px solid rgba($light, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: $md-width) {
      flex-direction: row;
    }
    &__rights {
      color: $light;
    }

    &__nav {
      .navigator {
        &__inner {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
          flex-direction: column;

          @media (min-width: $sm-width) {
            flex-direction: row;
          }

          .nav-item {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .full-logo__inner {
    justify-content: center;
  }
  .full-logo {
    height: auto;
    &__inner {
      justify-content: center;

      @media (min-width: $md-width) {
        justify-content: flex-start;
      }
    }
    .logo {
      &-icon {
        width: 2rem;
        margin-top: -2rem;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .navigator {
    &__inner {
      .nav-item {
        &__link {
          &:hover {
            .nav-item__link__label {
              color: $secondary;
            }
          }
          &__label {
            color: $light;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
}
