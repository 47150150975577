.newsletter-block {
  color: $light;
  padding-top: 6rem;
  margin-top: -6rem;
  &__inner {
    background-color: $tertiary;
    margin: auto;
    padding: 4rem 0;

    @media (min-width: $md-width) {
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__content {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    @media (min-width: $md-width) {
      flex-direction: row;
      align-items: flex-end;
      margin: 0;
      width: 65rem;
    }
    @media (min-width: $lg-width) {
    }

    &__inner {
      width: 50rem;
      @media (min-width: $md-width) {
        width: 50rem;
      }
    }

    &__title {
      font-family: $font-primary;
      font-size: 5.5rem;
      line-height: 5.5rem;
    }

    &__text {
      font-family: $font-secondary;
      position: relative;
      text-align: center;
      margin-top: 3rem;

      @media (min-width: $md-width) {
        margin-top: 2rem;
      }
      p {
        font-family: $font-secondary;
        position: relative;
        z-index: 2;
        font-size: 2.5rem;
        line-height: 2.5rem;

        .dark {
          font-family: $font-secondary;
          color: $tertiary;
          font-size: inherit;
          line-height: inherit;
        }
      }

      &__background {
        z-index: 1;
        position: absolute;
        display: flex;
        height: auto;
        width: 90%;
        top: 1.6rem;
        left: 6rem;
        img {
          width: 100%;
        }
      }
    }

    &__cite {
      margin: 4rem auto 0;
      text-align: center;
      font-family: $font-tertiary;
      font-size: 3.5rem;
      line-height: 3.5rem;

      @media (min-width: $md-width) {
        text-align: right;
        font-size: 4rem;
        line-height: 4rem;
        margin: 2rem 0rem 0 0;
      }
    }
    &__arrow {
      position: relative;
      display: block;
      transform: rotate(-100deg) scaleX(-1);
      width: 10rem;
      margin: 1rem 0 0 23rem;

      @media (min-width: $md-width) {
        transform: none;
        width: 12rem;
        margin: 0 0 0 1rem;
      }
      img {
        max-width: 100%;
      }
    }
  }
  &__form__container {
    width: 34rem;
    margin: 5rem auto 0 auto;
    @media (min-width: $md-width) {
      margin: 0;
    }
  }

  &__form {
    .alert-message {
      padding: 0;
      &__content {
        &.success {
          color: $light;
          border-color: $light;
        }
      }
    }
    &__elem {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      @media (min-width: $md-width) {
        justify-content: flex-start;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &__label {
        width: 8rem;
        text-align: right;
        margin-right: 2rem;
        font-size: 2rem;
        line-height: 2rem;
        font-family: $font-secondary;
        margin-left: -2rem;

        @media (min-width: $md-width) {
          font-size: 2.3rem;
          line-height: 2.3rem;
          width: 8rem;
          margin-left: 0;
        }
      }

      &__field {
        border: none;
        border-radius: 0;
        margin: 0;
        width: 16rem;
        padding: 0.5rem 1rem;
        font-style: normal;
        font-size: 1.6rem;
        @media (min-width: $md-width) {
          width: 65%;
        }
      }
      &__button {
        text-align: center;
        width: 65%;
        .btn {
          background-color: $light;
          color: $tertiary;
          font-family: $font-tertiary;
          font-size: 3rem;
          padding: 0.2rem 3rem;
          border-radius: 0.5rem;
          margin-top: 1rem;
          cursor: pointer;
          border: 1px solid $light;
          transition: color 0.3s ease, background-color 0.3s ease;

          @media (min-width: $md-width) {
            font-size: 3rem;
            line-height: 3rem;
            padding: 0.2rem 2rem;
          }

          &:hover {
            background-color: $tertiary;
            color: $light;
          }
        }
      }
    }
  }
}
